<script lang="ts" setup>
import { PropType } from 'vue'
import { ModifiersEditorTweak, ModifiersEditorGeneric } from '#components'
import { modifiersConfig } from '~~/src/config/Modifiers'
import { Modifiers, ModifierType, TweakModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import { useCloneObject } from '~~/src/hooks/useCloneObject'
import { EmitsEnum } from '~~/src/constants/emits'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { UserflowId } from '~~/src/constants/UserflowId'
import { utilModifiers } from '~~/src/utils/utilModifiers'
import { TextSizes } from '~~/src/constants/textSizes'
import { IndexedPrice } from '~~/src/submodules/sharedTypes/common/Price'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IconSizes } from '~~/src/constants/iconSizes'
import { useEllipsisCheck } from '~/hooks/useEllipsisCheck'
import { TranslationKey } from '~/i18n/TranslationKeys'

const props = defineProps({
	modifiers: { type: Object as PropType<Modifiers>, required: true },
	modifiersFilters: {
		type: Object as PropType<ModifierType[]>,
		default: Array.from(Object.values(ModifierType)) as ModifierType[],
	},
	hideModifiers: {
		type: Object as PropType<ModifierType[]>,
		default: [],
	},
	activeModifiers: {
		type: Object as PropType<ModifierType[]>,
		default: [],
	},
	disabledModifiers: {
		type: Object as PropType<ModifierType[]>,
		default: [],
	},
	currency: { type: Object as PropType<Currency>, required: false },
	associatedModifiers: { type: Object as PropType<Modifiers[]>, default: [] },
	linkedRoom: { type: Object as PropType<IndexedPrice> },
	isCompact: { type: Boolean },
	testIds: {
		type: Object as PropType<{
			'tweak-left-button'?: string
			'tweak-right-button'?: string
		}>,
		default: () => ({}),
	},
})
const {
	modifiers,
	modifiersFilters,
	activeModifiers,
	associatedModifiers,
	linkedRoom,
	isCompact,
	disabledModifiers,
	testIds,
} = toRefs(props)
const { clone, resetClone } = useCloneObject(modifiers)
const emit = defineEmits([EmitsEnum.Change, EmitsEnum.ErrorStatusChange])

const types = computed(() => {
	// const values = Object.keys(modifiers.value).filter(key => Object.values(ModifierType).some(el => el === key))
	const values = modifiersFilters.value.filter((key) => Object.values(ModifierType).some((el) => el === key))
	return values
})

// TODO: this should be in config, but nuxt breaks...
const getComponent = (modifier: ModifierType) =>
	modifier === ModifierType.Tweak ? ModifiersEditorTweak : ModifiersEditorGeneric

const getLabelClasses = (modifier: ModifierType) => {
	const width = isCompact.value ? 'w-full' : 'w-52'

	return width + ' ' + modifiersConfig[modifier].backgroundColor
}
const getIcon = (modifier: ModifierType) => modifiersConfig[modifier].icon
const getText = (modifier: ModifierType) => modifiersConfig[modifier].text
const getCurrentValue = computed(() => (modifier: ModifierType) => {
	return clone[modifier]
})

const getOriginalValue = (modifier: ModifierType) => modifiers.value[modifier]

const updateVal = (modifier: ModifierType, newVal: number | TweakModifierType) => {
	// @ts-ignore
	clone[modifier] = newVal
	// This "odd" code is here to make us
	//  return the actual object and not the reactive one
	emit(EmitsEnum.Change, { ...clone })
	// emitting whether there are errors detected or not
	emit(EmitsEnum.ErrorStatusChange, utilModifiers.hasErrors({ ...clone }, associatedModifiers.value))
}

const resetVal = (modifier: ModifierType) => {
	resetClone()
	delete clone[modifier]
	emit(EmitsEnum.Change, { ...clone })
	emit(EmitsEnum.ErrorStatusChange, utilModifiers.hasErrors({ ...clone }, associatedModifiers.value))
}

const modifiersErrors = computed(() => utilModifiers.getErrors({ ...clone }, associatedModifiers.value))
const editorComputedClass = (modifier: ModifierType) => {
	const disabled =
		disabledModifiers.value.length && disabledModifiers.value?.includes(modifier) ? 'opacity-30 grayscale' : ''
	const defaultBorder = modifiersErrors.value[modifier] != undefined ? 'border-error-300' : 'border-dark-blue-200'
	const { editorFocusBorder, editorHoverBorder } = modifiersConfig[modifier]
	const gridStyle = isCompact.value ? '' : 'grid-cols-[13rem_1fr]'
	return [defaultBorder, editorHoverBorder, editorFocusBorder, gridStyle, disabled].join(' ')
}

const gap = computed(() => (isCompact?.value ? 'gap-4' : 'gap-2'))

const textRef = ref()
const { hasEllipsis } = useEllipsisCheck(textRef)

const showNameTooltip = ref(false)

const modifierEditorReference = ref<InstanceType<typeof ModifiersEditorTweak>[]>()

defineExpose({
	resetModifiers: resetVal,
})
</script>

<template>
	<div class="flex flex-col" :class="gap">
		<div v-if="linkedRoom != undefined">
			<div
				class="grid grid-cols-[13rem_1fr] truncate rounded-md border border-dark-blue-200"
				:data-id="UserflowId.CollapsablePriceModifiersLinkedPrice"
			>
				<div class="flex w-52 items-center gap-2 bg-dark-blue-200 py-3 pl-3 text-dark-blue-800">
					<CommonIcon
						:icon-name="types.length < 1 ? SpSvg.CalendarModLink : SpSvg.CalendarModSemiLink"
						fill="fill-dark-blue-800"
						:icon-size="IconSizes.XS"
					/>
					<CommonTooltip
						:show="hasEllipsis && showNameTooltip"
						:text="linkedRoom.linkedRoomType.name as TranslationKey"
						class="flex-1 truncate"
						:stick-left="true"
					>
						<div
							class="truncate"
							@mouseenter="showNameTooltip = true"
							@mouseleave="showNameTooltip = false"
							ref="textRef"
						>
							<CommonText :text="linkedRoom.linkedRoomType.name as TranslationKey" />
						</div>
					</CommonTooltip>
				</div>
				<ModifiersEditorLinkedRoom :currency="currency" :linked-room="linkedRoom" class="px-3" />
			</div>
		</div>

		<template v-for="modifier in types">
			<div v-if="!hideModifiers.includes(modifier)">
				<div
					class="grid truncate rounded-md border"
					:class="editorComputedClass(modifier)"
					:data-id="UserflowId.CollapsablePriceModifiersSingleModifier"
				>
					<div class="flex items-center gap-2 py-2 pl-3 text-dark-blue-800" :class="getLabelClasses(modifier)">
						<CommonIcon :icon-name="getIcon(modifier)" fill="fill-dark-blue-800" />
						<CommonText :text="getText(modifier)" />
					</div>

					<component
						ref="modifierEditorReference"
						:is="getComponent(modifier)"
						class="relative flex justify-end px-3"
						:class="isCompact ? 'w-full py-2' : ''"
						:modifier-type="modifier"
						:current-value="getCurrentValue(modifier)"
						:original-value="getOriginalValue(modifier)"
						@change="(val: any) => updateVal(modifier, val)"
						@delete="() => resetVal(modifier)"
						:testIds="{
							'tweak-left-button': testIds?.['tweak-left-button'],
							'tweak-right-button': testIds?.['tweak-right-button'],
						}"
						:is-active="activeModifiers.includes(modifier)"
						:currency="currency"
						:has-error="modifiersErrors[modifier] != undefined"
						:is-compact="isCompact"
						:is-disabled="disabledModifiers.length && disabledModifiers.includes(modifier)"
					/>
				</div>
				<CommonText
					v-if="modifiersErrors[modifier] != undefined"
					:text="modifiersErrors[modifier]!"
					class="text-error-600"
					:text-size="TextSizes.PRODUCTIVE"
				/>
			</div>
		</template>
	</div>
</template>
