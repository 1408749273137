import { TranslationKey } from '~/i18n/TranslationKeys'
import { NumericVariationType } from '../submodules/sharedTypes/common/NumericVariationType'
import { Currency } from './../submodules/sharedTypes/common/Currency'
import { Justifier } from './../submodules/sharedTypes/common/Price'
import { LinkedRoomType } from './../submodules/sharedTypes/common/RoomType'
import { utilCurrency } from './utilCurrency'
import { utilNumber } from './UtilNumber'

class UtilJustifiers {
	translateJustifier(justifier: Justifier) {
		const key = (justifier.label || justifier.key).toUpperCase().split(' ').join('_')

		return useLocale().translate(key as TranslationKey)
	}

	justifierColor(justifier: Justifier) {
		return justifier.value < 0 ? 'text-error-400' : 'text-success-400'
	}

	readableJustifierValue(justifier: Justifier, currency: Currency) {
		const sign = justifier.value > 0 ? '+ ' : '- '
		const value = utilNumber.toCurrencyWithCents(Math.abs(justifier.value), currency)

		return sign + value
	}

	readableLinkedRoomModifier(linkedRoom: LinkedRoomType, currency: Currency, hideIdentifier: boolean = false) {
		switch (linkedRoom.variationType) {
			case NumericVariationType.Percentage: {
				return this._readableLinkedRoomModifierPercentage(linkedRoom, hideIdentifier)
				break
			}
			default: {
				return this._readableLinkedRoomModifierCurrency(linkedRoom, currency, hideIdentifier)
				break
			}
		}
	}

	private _readableLinkedRoomModifierPercentage(linkedRoom: LinkedRoomType, hideIdentifier: boolean) {
		const amount = Math.abs(linkedRoom.variationValue)
		let sign = linkedRoom.variationValue > 0 ? '+ ' : '- '
		if (amount == 0) {
			sign = ''
		}

		if (hideIdentifier) {
			const displayableAmount = Math.round(amount * 100)
			return `${sign}${displayableAmount}`
		}

		const displayableAmount = utilNumber.toPercentage(amount, 2)
		return `${sign}${displayableAmount}`
	}

	private _readableLinkedRoomModifierCurrency(linkedRoom: LinkedRoomType, currency: Currency, hideIdentifier: boolean) {
		const amount = Math.abs(linkedRoom.variationValue)
		let sign = linkedRoom.variationValue > 0 ? '+ ' : '- '
		if (amount == 0) {
			sign = ''
		}

		let displayableAmount =
			amount > 0 ? utilNumber.toCurrencyWithCents(amount, currency) : utilNumber.toCurrency(0, currency)

		if (hideIdentifier) {
			const symbol = utilCurrency.currentCurrency(currency)
			displayableAmount = displayableAmount.replace(symbol, '').trim()
		}

		return `${sign}${displayableAmount}`
	}
}

export const utilJustifiers = new UtilJustifiers()
